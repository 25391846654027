import React from "react";
import Image from "next/image";
import planCheck from "@src/static/img/plan-check.svg";
import featuresCheck from "@src/static/img/features-check.svg";
import featuresCross from "@src/static/img/features-cross.svg";
//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard", "pricing"])),
    },
  };
}

export default function BusinessPlan({
  productCategoryList,
  customerProductList,
  onGetSubscription,
  onManageStripePlan,
  highlight_point
}) {
  const { t: translate, i18n } = useTranslation("pricing");
  return (
    <>
    <div className="planMainBx">
      {/* <div className="mostPopularbx font-medium mb-2.5 mt-2.5 text-sm ">{translate("Most popular plan")}</div> */}
      <div class="planAndFeaturesBox">
        <div className="planPriceBx rounded-lg rounded-b-none border-b border-mor-black-300 lg:p-8 px-3 py-5 lg:pb-6">
          
          <div class="flex justify-between ">
            <h3 class="text-2xl font-semibold w-44">{translate("Business")} </h3>
            <div className="">
              <p class="flex items-end">
               
                <span class="text-5xl font-bold tracking-tight flex">
                  <span className="text-lg mr-2">{translate("SAR")}</span> 
                  <span>{productCategoryList?.price}</span>
                </span>

                <span className="text-sm font-semibold text-black-color-400 ml-0.5">
                  {productCategoryList?.plain_validate == "Forever"
                    ? productCategoryList?.plain_validate
                    : `/${productCategoryList?.plain_validate == 'month' ? `${translate('mo')}` : `${translate('mo')}`}`}
                </span>
              </p>
            </div>
          </div>
          <p className="text-sm text-gray-400 text-right h-5"></p>
          {customerProductList.length > 0 ? (
            <span
              className="planBuyBtn mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold cursor-pointer bg-mor-yellow-500 text-mor-black-500"
              onClick={
                customerProductList[0]?.items?.data[0]?.plan?.id ==
                productCategoryList?.price_id
                  ? null
                  : () => onManageStripePlan(productCategoryList)
              }
            >
              {customerProductList[0]?.items?.data[0]?.plan
                ?.id == productCategoryList?.price_id
                ? `${translate("Current Plan")}`
                : `${
                    productCategoryList?.plain_validate == "Forever"
                      ? `${translate("Join for free")}`
                      : `${translate("Upgrade Plan")}`
                  }`}
            </span>
          ) : (
            <span
              className="planBuyBtn mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold cursor-pointer bg-mor-yellow-500 text-mor-black-500"
              onClick={(e) => onGetSubscription(productCategoryList, e)}
            >
              {`${
                productCategoryList?.plain_validate == "Forever" ? "" : `${translate("Get")}`
              } ${productCategoryList?.plan_name} ${translate("Plan")}`}
            </span>
          )}
        </div>
        <div className="featuresBox lg:p-8 py-4 px-3">
        <h3 className="font-semibold text-mor-yellow-500 mb-1">
            {translate("Perfect for E-commerce & businesses")}
          </h3>
          <p className="text-sm">{translate("Includes free & creators features plus")}</p>
          <ul role="list" class=" font-medium   mt-4 text-sm">
          {highlight_point && highlight_point.length > 0
              ? highlight_point.map((item, index) => (
                  <li className="flex gap-x-3 py-1.5" key={index}>
                    <Image src={planCheck} alt="" /> {item?.fuature_name}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      </div>
    </>
  );
}
