import router from "next/router";
import axios from "axios";
import { getHeaders } from "./apiHeaders";

let headers = {};
let response = "";

const siteService = {
  //get Product Category
  getProductCategoryById: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      response = await axios.get(
        `${process.env.STRIPE_API_URL}prices?product=${payload}`,
        (headers = { headers })
      );
      return response?.data?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get Products
  getProducts: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.get(
        `${process.env.STRIPE_API_URL}products?limit=${payload}`,
        (headers = { headers })
      );

      return response?.data?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },
  //get Products Data
  getProductData: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.get(
        `${process.env.STRIPE_API_URL}products/${payload}`,
        (headers = { headers })
      );
      return response?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },

  //get Products by ID
  getProductsByID: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.get(
        `${process.env.STRIPE_API_URL}products?ids[0]=${payload}`,
        (headers = { headers })
      );

      return response?.data?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },

  //get Products
  getCustomerSubscriptions: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.get(
        `${process.env.STRIPE_API_URL}subscriptions?customer=${payload}`,
        (headers = { headers })
      );

      return response?.data?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },

  //delete subscriptions
  deleteSubscriptions: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.delete(
        `${process.env.STRIPE_API_URL}subscriptions/${payload}`,
        (headers = { headers })
      );

      return response?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },

  // get all Invoice
  getAllInvoices: async function (payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.get(
        `${process.env.STRIPE_API_URL}invoices?customer=${payload}`,
        (headers = { headers })
      );

      return response?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },

  // get all Invoice
  updateCustomer: async function (strip_id, payload) {
    try {
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: process.env.AUTHORIZATION_STRIPE_SECRET_KEY,
      };
      const response = await axios.post(
        `${process.env.STRIPE_API_URL}customers/:${strip_id}`,
        payload,
        (headers = { headers })
      );

      return response?.data;
    } catch (error) {
      // console.log("error is: ", error);
      return error?.response?.data;
    }
  },
};

export default siteService;
