import React from "react";
import Image from "next/image";
import planCheck from "@src/static/img/plan-check.svg";

//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard", "pricing", "index"])),
    },
  };
}

export default function FreePlan({
  productCategoryList,
  customerProductList,
  onGetSubscription,
  onManageStripePlan,
  productCategory,
  highlight_point
}) {
  const { t: translate, i18n } = useTranslation("pricing");
  
  return (
    <>
      <div class="planMainBx">
        <div className="planAndFeaturesBox">
        <div className="planPriceBx rounded-lg	 rounded-b-none border-b border-mor-black-300 lg:p-8 px-3 py-5 lg:pb-6">
          <div class="flex justify-between ">
            <h3 class="text-2xl font-semibold w-44">{translate("Free")} </h3>
            <div className="">
              <p class="flex items-end">
                <span class="text-5xl font-bold tracking-tight  flex">
                  <span className="text-lg mr-2">{translate("SAR")}</span>
                  <span>{productCategoryList?.price}</span>
                </span>
              </p>
            </div>
          </div>
          <p className="text-sm text-gray-400 text-right h-5">
            {productCategoryList?.disc}
          </p>
          {customerProductList.length > 0 ? (
            <span
              className="planBuyBtn mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold  border border-mor-black-300 cursor-pointer"
              onClick={
                customerProductList[0]?.items?.data[0]?.plan?.id ==
                productCategoryList?.price_id
                  ? null
                  : () => onManageStripePlan(productCategoryList)
              }
            >
              {customerProductList[0]?.items?.data[0]?.plan
                ?.id == productCategoryList?.price_id
                ? `${translate("Current Plan")}`
                : `${
                    productCategoryList?.plain_validate == "Forever"
                      ? `${translate("Join for free")}`
                      : `${translate("Upgrade Plan")}`
                  }`}
            </span>
          ) : (
            <span
              className="planBuyBtn mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold  border border-mor-black-300 cursor-pointer"
              onClick={(e) => onGetSubscription(productCategoryList, e)}
            >
              {`${
                productCategoryList?.plain_validate == "Forever"
                  ? ""
                  : `${translate("Get")}`
              }  ${translate("Join for Free")}`}
            </span>
          )}
        </div>
        <div className="featuresBox lg:p-8 py-4 px-3">
          <h3 className="font-semibold text-mor-yellow-500 mb-1">
            {translate("Try Mor for free")}
          </h3>
          <p className="text-sm">
            {translate("No Credit Card Required")}
          </p>
          <ul role="list" class=" font-medium  mt-4 text-sm">
            {highlight_point &&
            highlight_point.length > 0
              ? highlight_point.map((item, index) => (
                  <li className="flex gap-x-3 py-1.5" key={index}>
                    <Image src={planCheck} alt="" /> {item?.fuature_name}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      </div>
    </>
  );
}
