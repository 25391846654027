import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import logo from "@src/./static/img/logo.svg";
import Link from "next/link";
import Head from "next/head";
import { getAuthToken, setCurrentUser } from "@src/utils/auth";
import LanguageSwitcher from "@src/components/common/LanguageSwitcher";
// Auth0
import { useUser } from "@auth0/nextjs-auth0/client";
//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// export async function getStaticProps({ locale }) {
//   return {
//     props: {
//       ...(await serverSideTranslations(locale, [
//         "dashboard"
//       ])),
//     },
//   };
// }

const PublicHeader = (props) => {
  const { locale } = useRouter();
  const router = useRouter();
  const { t: translate } = useTranslation("index");
  const { routing, meta_title } = props;
  const { user, error, isLoading } = useUser();
  const [isChild, setIsChild] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const toggleChildClass = () => {
    setIsChild(!isChild);
    document.body.classList.remove("overflow-hidden");
  };
  const menuOpenBtn = () => {
    setIsChild(!isChild);
    document.body.classList.add("overflow-hidden");
  };
  const bodyClassRemove = () => {
    setIsChild(!isChild);
    document.body.classList.remove("overflow-hidden");
  };

  useEffect(() => {
    const temp = getAuthToken();
    if (temp) {
      setIsLogin(true);
    }
  }, []);

  const handleFeatureClick = () => {
    if (router.pathname !== "/") {
      router.push("/?scrollToFeature=true");
    } else {
      scrollToFeature();
    }
  };

  const scrollToFeature = () => {
    const featureElement = document.getElementById("feature-section");
    if (featureElement) {
      featureElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setLocales = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("language", locale);
    }
  };

  return (
    <>
      <Head>
        {/* Primary Meta Tags */}
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{meta_title}</title>
        <meta
          name="description"
          content="Single Link, Maximum Reach & Engagement."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href="/path-to-your-favicon.ico" type="image/x-icon" />

        <link rel="canonical" href="https://mor.link/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mor.link/" />
        <meta
          property="og:title"
          content="Mor.link! - One link to grow your business."
        />
        <meta
          property="og:description"
          content="One link to grow your business."
        />
        <meta
          property="og:image"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />
        <meta
          property="og:image:width"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />
        <meta
          property="og:image:height"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://mor.link/" />
        <meta
          property="twitter:title"
          content="Mor.link! - One link to grow your business."
        />
        <meta
          property="twitter:description"
          content="One link to grow your business."
        />
        <meta
          property="twitter:image"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />

        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WBCQTZMN"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        {/* intercom */}
        {/* <script dangerouslySetInnerHTML={{ __html: intercom_data}} /> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WBCQTZMN');
          `,
          }}
        />
        {/* intercom */}
      </Head>
      <div
        className={
          isChild
            ? "block md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black-color-900 opacity-80 z-10"
            : "hidden"
        }
      ></div>
      <div className="header pl-4 md:pl-6 pr-4 md:pr-6 bg-mor-blue-500 text-white">
        <div className="max-w-screen-xl mx-auto flex justify-between py-4">
          <div className="headLeftBox xl:w-80 rtl:xl:w-96 rtl:w-auto w-auto flex items-center">
            <Link className="mr-2 rtl:mr-0 rtl:ml-2" href="/">
              <Image src={logo} alt="" />
            </Link>
          </div>
          <div className="headMiddle">
            <ul
              className={
                isChild
                  ? "max-w-xs shadow-md z-10 w-full text-base font-semibold fixed left-0 top-0 h-screen  md:flex md:top-auto md:mx-4 md:h-auto md:relative md:left-auto md:max-w-none md:shadow-none md:w-auto transition-all  bg-mor-black-500 md:bg-transparent"
                  : "max-w-xs shadow-md z-10 w-full text-base font-semibold fixed -left-full top-0 h-screen  md:flex md:top-auto md:mx-4 md:h-auto md:relative md:left-auto md:max-w-none md:shadow-none md:w-auto transition-all bg-mor-black-500 md:bg-transparent"
              }
            >
              <li className="block mt-3 md:hidden">
                <a className="inline-block p-2" href="#">
                  <Image src={logo} alt="" />
                </a>
              </li>
              <li
                className="block absolute right-3 rtl:right-auto rtl:left-3 top-6 md:hidden"
                onClick={toggleChildClass}
              >
                <a href="#">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_406_16968)">
                      <path
                        d="M18.3 5.70973C17.91 5.31973 17.28 5.31973 16.89 5.70973L12 10.5897L7.10997 5.69973C6.71997 5.30973 6.08997 5.30973 5.69997 5.69973C5.30997 6.08973 5.30997 6.71973 5.69997 7.10973L10.59 11.9997L5.69997 16.8897C5.30997 17.2797 5.30997 17.9097 5.69997 18.2997C6.08997 18.6897 6.71997 18.6897 7.10997 18.2997L12 13.4097L16.89 18.2997C17.28 18.6897 17.91 18.6897 18.3 18.2997C18.69 17.9097 18.69 17.2797 18.3 16.8897L13.41 11.9997L18.3 7.10973C18.68 6.72973 18.68 6.08973 18.3 5.70973Z"
                        fill="#ffffff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_406_16968">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  onClick={handleFeatureClick}
                  className="px-4 py-2 block cursor-pointer"
                >
                  {translate("Features")}
                </a>
              </li>

              {/* <li onClick={bodyClassRemove}>
                <Link
                  className={
                    routing == "integrations"
                      ? "active px-4 py-2 block hover:text-project-color-500 md:hover:text-black-color-500"
                      : "px-4 py-2 block hover:text-project-color-500 md:hover:text-black-color-500"
                  }
                  href="/integrations"
                >
                  Integrations
                </Link>{" "}
              </li> */}
              <li onClick={bodyClassRemove}>
                <Link
                  href="/pricing"
                  className={
                    routing == "pricing"
                      ? "active px-4 py-2 block "
                      : "px-4 py-2 block "
                  }
                >
                  {translate("Pricing")}
                </Link>
              </li>
              <li onClick={bodyClassRemove}>
                <a
                  className="px-4 py-2 block "
                  href="https://help.mor.link"
                  target="_blank"
                >
                  {translate("Help Center")}
                </a>
              </li>
              <li className="md:hidden" onClick={bodyClassRemove}>
                {user ? (
                  <a></a>
                ) : (
                  <Link
                    className="px-4 py-2 block mr-2 hover:text-project-color-500"
                    href={`/api/auth/login?language=${locale}`}
                    onClick={setLocales}
                  >
                    {translate("Login")}
                  </Link>
                )}
              </li>
              <li className="md:hidden" onClick={bodyClassRemove}>
                {user ? (
                  <Link
                    className="px-4 py-2 block "
                    href="/dashboard"
                    // onClick={() => redirectForAuth("user/auth/signup")}
                  >
                    {translate("Go to dashboard")}
                  </Link>
                ) : (
                  <Link
                    className="px-4 py-2 block "
                    href="/auth/sign-up"
                    // onClick={() => redirectForAuth("user/auth/signup")}
                  >
                    {translate("Create Account")}
                  </Link>
                )}
              </li>
            </ul>
          </div>
          <div className="headRightBox xl:w-80 rtl:xl:w-96 rtl:w-auto w-auto">
            <ul className="flex text-sm font-semibold items-center justify-end">
              <LanguageSwitcher />
              <li className="hidden md:inline-block" onClick={bodyClassRemove}>
                {user ? (
                  <a></a>
                ) : (
                  <Link
                    className="loginBtn px-4 py-2.5 inline-block bg-transparent border border-mor-blue-500 rounded-lg mr-4 rtl:ml-4 rtl:mr-0"
                    href={`/api/auth/login?language=${locale}`}
                  >
                    {translate("Login")}
                  </Link>
                )}
              </li>
              <li className="hidden md:inline-block" onClick={bodyClassRemove}>
                {user ? (
                  <Link
                    className="px-4 py-2.5 inline-block bg-mor-yellow-500 hover:bg-mor-yellow-400 text-mor-black-500 rounded-lg "
                    href="/dashboard"
                    // onClick={() => redirectForAuth("user/auth/signup")}
                  >
                    {translate("Go to dashboard")}
                  </Link>
                ) : (
                  <Link
                    className="px-4 py-2.5 inline-block bg-mor-yellow-500 hover:bg-mor-yellow-400 text-mor-black-500 rounded-lg"
                    href={`/api/auth/login?language=${locale}`}
                    // onClick={() => redirectForAuth("user/auth/signup")}
                  >
                    {translate("Create Account")}
                  </Link>
                )}
              </li>

              <li className="md:hidden ml-4 mt-3" onClick={menuOpenBtn}>
                <a href="#">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 9.66675H1C0.448 9.66675 0 9.21875 0 8.66675C0 8.11475 0.448 7.66675 1 7.66675H23C23.552 7.66675 24 8.11475 24 8.66675C24 9.21875 23.552 9.66675 23 9.66675ZM23 2H1C0.448 2 0 1.552 0 1C0 0.448 0.448 0 1 0H23C23.552 0 24 0.448 24 1C24 1.552 23.552 2 23 2ZM23 17.3333H1C0.448 17.3333 0 16.8853 0 16.3333C0 15.7813 0.448 15.3333 1 15.3333H23C23.552 15.3333 24 15.7813 24 16.3333C24 16.8853 23.552 17.3333 23 17.3333Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicHeader;
