import React from "react";
import Image from "next/image";
import loaderIcon from "@src/static/img/loader-icon.svg";
import { Player } from "@lottiefiles/react-lottie-player";

//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard"])),
    },
  };
}

const MainLoader = () => {
  const { t: translate } = useTranslation(["dashboard"]);
  return (
    <div
      role="status"
      className="fixed top-0 !right-0 !left-0 bottom-0 flex justify-center items-center bg-black bg-opacity-60 z-50 backdrop-blur-sm"
    >
      {/* <Image src={loaderIcon} height={100} width={100} /> */}
      <Player
        autoplay
        loop
        src="https://lottie.host/3d4fc626-e249-40b3-bdce-f75da68c03f1/2VQU7nDhaE.json"
        style={{ height: "auto", width: "auto" }}
      ></Player>
      <span className="sr-only">{translate("Loading...")}</span>
    </div>
  );
};

export default MainLoader;
